var layoutLanguageCulture, userCountryCode, localizeJsProjectKey, urlAction;

class LocalizeJSLanguageSelection {
    constructor() {
        this.selectedLanguageCulture = this.selectedLanguageCulture.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.setBindings = this.setBindings.bind(this);
        this.updateLanguageOptions = this.updateLanguageOptions.bind(this);
        this.init = this.init.bind(this);
        this.getAvailableLanguages = this.getAvailableLanguages.bind(this);
    }

    selectedLanguageCulture() {
        const select = $('.custom-localize-select');
        const languageCode = select.val();
        return languageCode;
    }

    setLanguage(language) {
        $('.custom-localize-select option')
            .each(function() {
                if ($(this).val() === language) {
                    $(this).attr('selected', 'selected');
                }
            });

        Localize.setLanguage(language);
    }

    setBindings() {
        Localize.on('setLanguage', this.updateLanguageOptions);

        $(document)
            .on('change',
                '.custom-localize-select',
                function() {
                    Localize.setLanguage($(this).val());
                });
    }

    updateLanguageOptions(language) {
        if (layoutLanguageCulture !== language.to) {
            $.post(urlAction, { languageCulture: language.to });
            layoutLanguageCulture = language.to;
        }

        // Update the select if needed.  Guard against onChange loop.
        if (this.selectedLanguageCulture() !== language.to) {
            $('.custom-localize-select').val(language.to);
        };

        $('.custom-localize-select option')
            .each(function() {
                if ($(this).val() === language.to) { // EDITED THIS LINE
                    $(this).attr('selected', 'selected');
                } else {
                    $(this).removeAttr('selected');
                }
            });
    }

    init() {
        Localize.initialize({
            key: localizeJsProjectKey,
            translateTitle: false,
            rememberLanguage: true
        });

        Localize.getAvailableLanguages(this.getAvailableLanguages);

        this.setBindings();

        Localize.setLanguage(layoutLanguageCulture);

        if (userCountryCode === 'USA') {
            Localize.hideWidget();
        } 
    }

    getAvailableLanguages(err, languages) {
        const select = $('.custom-localize-select');
        select.empty();
        for (var i = 0, len = languages.length; i < len; i++) {
            select.append(new Option(languages[i].name, languages[i].code));
        }
    }
}